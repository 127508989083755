import { Protocol } from "gravatar"
import { String, Undefined } from "runtypes"

export const DEVELOPMENT = !!process.env.NEXT_PUBLIC_DEVELOPMENT

export const COOKIE_DOMAIN = String.Or(Undefined).check(process.env.NEXT_PUBLIC_COOKIE_DOMAIN)

export const PERSONABLE_SERVER = String.check(process.env.NEXT_PUBLIC_PERSONABLE_SERVER)
export const SHAREABLE_SERVER = String.check(process.env.NEXT_PUBLIC_SHAREABLE_SERVER)
export const QUESTIONER_SERVER = String.check(process.env.NEXT_PUBLIC_QUESTIONER_SERVER)
export const STUMPER_SERVER = String.check(process.env.NEXT_PUBLIC_STUMPER_SERVER)
export const QUESTIONABLE_SERVER = String.check(process.env.NEXT_PUBLIC_QUESTIONABLE_SERVER)
export const MACE_SERVER = String.check(process.env.NEXT_PUBLIC_MACE_SERVER)
export const HELPABLE_SERVER = String.check(process.env.NEXT_PUBLIC_HELPABLE_SERVER)
export const JITSI_SERVER = String.check(process.env.NEXT_PUBLIC_JITSI_SERVER)
export const API_SERVER = String.check(process.env.NEXT_PUBLIC_API_SERVER)
export const JEED_SERVER = String.check(process.env.NEXT_PUBLIC_JEED_SERVER)

export const CURRENT_NUMBER = "124"
export const MAX_ASSISTANT_CONTRIBUTION_COUNT = 4

export const REFERENCE_SEMESTER_SHORTHAND = "Fall2023"

export const MP_STAFF_RELEASED = false
export const MP_RELEASED = false

export const ZOOM_MEETING_ID = undefined

export const OLD_SEMESTERS = [
  { name: "Fall 2020", shorthand: "Fall2020" },
  { name: "Spring 2021", shorthand: "Spring2021" },
  { name: "Summer 2021", shorthand: "Summer2021" },
]

export const VALID_SEMESTERS = [
  { name: "Fall 2021", shorthand: "Fall2021" },
  { name: "Spring 2022", shorthand: "Spring2022" },
  { name: "Summer 2022", shorthand: "Summer2022" },
  { name: "Fall 2022", shorthand: "Fall2022" },
  { name: "Spring 2023", shorthand: "Spring2023" },
  { name: "Summer 2023", shorthand: "Summer2023" },
  { name: "Fall 2023", shorthand: "Fall2023" },
  { name: "Spring 2024", shorthand: "Spring2024" },
  { name: "Fall 2024", shorthand: "Fall2024" },
]

export const ALL_SEMESTERS = [...OLD_SEMESTERS, ...VALID_SEMESTERS]

export type ValidSemesters =
  | "Fall2021"
  | "Spring2022"
  | "Summer2022"
  | "Fall2022"
  | "Spring2023"
  | "Summer2023"
  | "Fall2023"
  | "Spring2024"
  | "Fall2024"

export const GRAVATAR_OPTIONS = {
  r: "pg",
  s: "120",
  d: encodeURI("https://www.cs124.org/images/logo-120x120.png"),
  protocol: "https" as Protocol,
}

export const LARGE_GRAVATAR_OPTIONS = {
  r: "pg",
  s: "460",
  d: encodeURI("https://www.cs124.org/images/logo-460x460.png"),
  protocol: "https" as Protocol,
}

export const WORKAHEAD = 2
export const WORKBEHIND = 1

export const DEFAULT_STUMPER_SKIP_COUNT = 2
export const DEFAULT_STUMPER_CHECK_COUNT = 2

export const DISABLE_QUIZ_SECURITY = !!process.env.NEXT_PUBLIC_DISABLE_QUIZ_SECURITY

export const AVAILABLE_ASSESSABLES = [
  {
    path: "Fall2024/CS124/__language__/Q3",
    version: "2024.9.0",
    duration: 50,
  },
]

export const QUIZ_PREFIX = "/quizzes/"
export const IN_CBTF = false
export const SHOW_PROCTORING_ZOOM = !!ZOOM_MEETING_ID
export const NO_LANGUAGE_REDIRECT = false

export const DISABLE_COOKIE_WARNING = false

export const DEFAULT_AUTHOR = "challen@illinois.edu"

export const DEFAULT_DEADLINES = {
  Fall2023: {
    EARLY_HW: "2023-09-05",
  },
  Spring2024: {
    RELEASE_DATE: "2024-01-12",
    EARLY_HW: "2024-01-31",
  },
  Fall2024: {
    RELEASE_DATE: "2024-08-23",
    EARLY_HW: "2024-09-11",
  },
}

export const ASSISTANT_TRAINING = true

export const DEFAULT_PRACTICE_COUNT = 8
export const DEFAULT_LATE_PENALTY_PER_DAY = 2

export const SHOW_VERSION_WARNINGS = true

export const MAX_TIME_DIFF_SECONDS = 60
